#top-nav {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid purple;

    nav {
        display: flex;
        padding-block: 0.5rem;

        a {
            padding-inline: 1rem;
            border-right: 1px solid grey;
            text-decoration: none;
            color: gray;

            &[data-state="on"] {
                color: purple;
            }

            &:hover {
                color: purple;
                // text-decoration: underline;
            }
        }
    }

    #more-options {
        padding-inline: 1rem;
        padding-block: 0.5rem;
    }

    #version {
        text-align: center;
        padding-block: 1rem;
    }
}

.module-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    padding-block: 1.5rem;

    .module-card {
        border: 1px solid lightgrey;
        padding: 0.5rem 1rem;
        border-radius: 12px;
        flex-grow: 1;
        width: 25rem;
        max-width: 100%;

        a {
            color: inherit;
            text-decoration: none;
        }
    }
}
