#home-page {
    display: flex;
    width: 100%;
    min-height: 100dvh;
    padding: 1rem;

    .content {
        width: 25rem;
        max-width: 100%;
        margin: auto;
    }
}