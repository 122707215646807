body {
  margin: 0;
  font-family: "Noto Sans JP Variable", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
}

* {
  box-sizing: border-box;
}

input,
textarea {
  font-size: inherit;
  font-family: inherit;
  background: white;
  border: 1px solid lightgrey;
  border-radius: 6px;
  padding: 0.25rem 0.25rem;
  resize: none;
  max-width: 100%;
}

button {
  font-family: inherit;
  font-size: inherit;
  padding: 0;
  border: 0;
  background-color: inherit;
  cursor: pointer;
  color: #006aff;

  &.nowrap {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.meta {
    color: grey;

    &:hover {
      color: purple;
    }
  }

  &.danger {
    color: red;

    &:hover {
      color: red;
    }
  }

  &.outline {
    border: 1px solid;
    padding: 0.75rem;
    border-radius: 12px;
  }
}

.fullwidth {
  width: 100%;
}

.centered {
  text-align: center;
}

.title {
  font-size: 16px;
  color: purple;
}

.small-title {
  font-size: 13px;
  color: purple;
}

.actions-list {
  display: flex;

  >div {
    padding-inline: 1rem;
    border-right: 1px solid lightgrey;
    overflow: hidden;
    display: flex;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: 0;
      padding-right: 0;

      &.danger {
        margin-left: auto;
      }
    }
  }
}

.flex-col {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}