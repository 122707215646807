.modal {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100dvh;
    width: 100dvw;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms ease-in-out;
    z-index: 999;

    &[data-state="open"] {
        opacity: 1;
        pointer-events: all;
    }

    .content {
        width: 100%;
        background-color: white;
        border-radius: 12px;

        > div {
            padding-inline: 1rem;
        }

        .header {
            padding-block: 1rem;
            border-bottom: 1px solid purple;
            display: flex;
            justify-content: space-between;
            gap: 1rem;
        }
    }
}
