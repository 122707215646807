.custom-select {
    overflow: hidden;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;
        padding: 0.15rem 0.5rem;
        border: 1px solid lightgray;
        border-radius: 6px;
        cursor: pointer;

        .label {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .options {
        padding-top: 0.25rem;
        position: absolute;
        background-color: white;
    }

    .option-wrapper {
        opacity: 1;
        padding: 0.5rem 0.5rem;
        border-left: 1px solid lightgray;
        border-right: 1px solid lightgray;
        transition: opacity 50ms ease-in-out;

        &:first-child {
            border-radius: 8px 8px 0 0;
            border-top: 1px solid lightgray;
        }
        &:last-child {
            border-bottom: 1px solid lightgray;
            border-radius: 0 0 8px 8px;
        }

        .option {
            padding: 0.5rem 0.5rem;
            border-radius: 6px;
            cursor: pointer;

            &:hover {
                background-color: rgba(0, 0, 0, 0.05);
            }
        }

        &.selected {
            .option {
                background-color: rgba(0, 0, 0, 0.05);
            }
        }
    }

    &[data-state="open"] {
        .options {
            z-index: 999;
        }
    }

    &[data-state="closed"] {
        .options {
            max-height: 0;
            overflow: hidden;
        }
        .option-wrapper {
            opacity: 0;
        }
    }
}
