#menu-edit-page {
    padding-block: 1rem;

    > .header {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .menu-selection {
            display: flex;
            gap: 0.5rem;

            > div {
                overflow: hidden;
            }
        }

        .section-selection {
            display: flex;
            gap: 0.5rem;

            > div {
                overflow: hidden;
            }
        }
    }

    .editor {
        padding-top: 2rem;
        .items {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .item-row {
                display: flex;

                .item {
                    flex-grow: 1;
                    overflow: hidden;

                    .preview {
                        display: contents;

                        .header {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            padding: 0.25rem 0.5rem;
                            border: 1px solid lightgray;
                            border-radius: 8px;
                        }
                    }

                    .actions-list {
                        padding-inline: 0.5rem;
                    }
                }
                .actions {
                    display: flex;
                    margin-bottom: auto;
                    margin-top: 0.25rem;
                    margin-left: 0.25rem;

                    button {
                        padding-inline: 0.25rem;
                    }
                }
                .chef-selection {
                    background-color: #c3fcf2;
                    border-radius: 4px;
                    padding: 0.25rem 0.5rem;
                }
                .hidden {
                    background-color: #ffc072;
                    border-radius: 4px;
                    padding: 0.25rem 0.5rem;
                }
            }

            .modal {
                .fields {
                    width: 100%;
                    padding-bottom: 1.5rem;

                    > div {
                        margin-top: 0.5rem;
                        &.prices,
                        &.add-ons,
                        &.customization-options {
                            margin-top: 1rem;
                        }

                        input,
                        textarea {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .item-edit-modal {
        .menu {
            padding: 0.5rem 0;
        }

        .price,
        .add-on,
        .customization-option {
            display: flex;
            gap: 0.5rem;
            display: flex;
            gap: 0.5rem;

            > div {
                overflow: hidden;

                &.description {
                    flex-grow: 1;
                    input {
                        width: 100%;
                    }
                }

                &.currency {
                    width: 4rem;
                }

                &.amount {
                    width: 6rem;
                }

                &.trash {
                    padding-block: 0.25rem;

                    svg {
                        width: 1rem;
                        path {
                            stroke: red;
                        }
                    }
                }
            }
        }
    }
}
